import { NewIcon, Typography } from "@lysaab/ui-2";
import { FunctionComponent, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./FAQ.scss";

const faqItems: FaqItemProps[] = [
  {
    title: "Vilka pensioner kan jag flytta?",
    content: "Kommer snart...",
  },
  {
    title: "Hur lång tid tar det?",
    content:
      "Vi strävar efter att flytten ska ske så snabbt som möjligt. Vanligtvis säger vi att en flytt tar mellan 3 och 6 månader. Hur lång tid det tar beror till stor del på handläggningstiden hos ditt nuvarande pensionsbolag. Dock är tiden du är utanför marknaden endast några dagar.",
  },
  {
    title: "Hur går en flytt till?",
    content: "Kommer snart...",
  },
  {
    title: "Vad har Lysa för avgift?",
    content: "Kommer snart...",
  },
  {
    title: "Vad innebär anpassad risknivå?",
    content: "Kommer snart...",
  },
];

export const FAQ: FunctionComponent = () => {
  const [isCodeActivated, setIsCodeActivated] = useState(false);

  useEffect(() => {
    const requiredSequence = [38, 38, 40, 40]; // Up, Up, Down, Down
    let currentIndex = 0;

    const handleKeyDown = (event: KeyboardEvent) => {
      const keyCodeMap: { [key: string]: number } = {
        ArrowUp: 38,
        ArrowDown: 40,
      };

      if (event.key in keyCodeMap) {
        const keyCode = keyCodeMap[event.key as keyof typeof keyCodeMap];

        if (keyCode === requiredSequence[currentIndex]) {
          currentIndex++;

          if (currentIndex === requiredSequence.length) {
            setIsCodeActivated((prev) => !prev);
            currentIndex = 0;
          }
        } else {
          currentIndex = 0;
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (!isCodeActivated) {
    return null;
  }

  return (
    <section className="transfer-pension-intro-faq">
      <Typography type="h3">
        <TranslatedText id="sweden.transfer-pension.intro.faq.header" />
      </Typography>
      {faqItems.map((item, index) => (
        <AccordionItem key={index} title={item.title} content={item.content} />
      ))}
    </section>
  );
};

interface FaqItemProps {
  title: string;
  content: string;
}

const AccordionItem: FunctionComponent<FaqItemProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <button onClick={toggleOpen} className="faq-button">
        <Typography type="label" className="title">
          {title}
        </Typography>
        <span
          className={isOpen ? "chevron chevron-open" : "chevron chevron-closed"}
        >
          <NewIcon.ChevronRight />
        </span>
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{
              opacity: 0,
              height: 0,
            }}
            transition={{ duration: 0.3 }}
          >
            <Typography type="body" className="faq-content">
              {content}
            </Typography>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
