import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./DigitalSigning.scss";
import { useLocation, useParams } from "react-router";
import { Button, InputErrorList, Typography } from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { Move, useTransfer } from "../TransferContext";
import {
  PensionMoveSigningOptions,
  dataLifePensionMove,
} from "../../../../../data/dataLifePensionMove";
import { UiScriveStatus, useScriveStatusForCase } from "./useScrive";
import { TranslatedText } from "../../../../../components/TranslatedText";

import "./DigitalSigning.scss";
import { SignMove } from "./SignMove";
import { Modal } from "../../../../../components/modal/Modal";
import { SignStepIndicator } from "./SignStepIndicator";

const allIsSigned = (statuses: UiScriveStatus[]) =>
  statuses.every((s) => s.uiStatus === "SIGNED");
const atLeastOneIsSigned = (statuses: UiScriveStatus[]) =>
  statuses.some((s) => s.uiStatus === "SIGNED");

const nextMoveToSign = (moves: Move[], statuses: UiScriveStatus[]) => {
  return moves.find((move) => {
    const status = statuses.find((s) => s.accountId === move.id);
    if (!status) {
      return false;
    }

    const { uiStatus } = status;
    return uiStatus !== "SIGNED" && uiStatus !== "RETRY";
  });
};

type RouteParams = {
  caseId: string;
};

interface Props {
  next: (caseId: string) => void;
}

function useUrlQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const DigitalSigning: FunctionComponent<Props> = ({ next }) => {
  const { caseId } = useParams<RouteParams>();
  const urlQuery = useUrlQuery();
  // Trigger a refetch of case data?
  const [transfer, setTransfer] = useTransfer();
  const [shouldShowRequiredError, setShouldShowRequiredError] = useState(false);
  const [shouldShowLeftWarning, setShouldShowLeftWarning] = useState(false);
  const intl = useIntl();
  // TODO: Package this better. Put in a hook? Only do if we don't already have data?
  useEffect(() => {
    dataLifePensionMove.getMove(caseId).then((data) => {
      // TODO: getMove return "amount" instead of "currentWorth". The "map" take care of it.
      // Check and align this with Hampus.
      const normalisedMoves = data.moves.map(({ amount, ...rest }) => {
        return { ...rest, currentWorth: amount };
      });
      setTransfer({
        moves: normalisedMoves,
        caseId: data.caseId,
        takenRiskDeviation: Number(data.advice.takenRiskDeviation),
        withdrawalAge: Number(data.advice.withdrawalAge),
        // TODO: withdrawalLenght spelling error
        withdrawalMonths: Number(data.advice.withdrawalLenght) * 12,
        repayment: data.moves.at(0)?.repayment || true,
      });
    });
  }, [caseId, setTransfer]);

  const moves = transfer.moves.filter(
    (move) => move.signing === PensionMoveSigningOptions.SCRIVE
  );
  const hasManualSigningMove = transfer.moves.some(
    (move) => move.signing === PensionMoveSigningOptions.MANUAL
  );

  const onSigningStarted = useCallback((status: UiScriveStatus) => {
    window.location.href = status.signingUrl;
  }, []);

  const { data: scriveStatus, startSigning } = useScriveStatusForCase({
    caseId,
    signedMoveIdHint: urlQuery.get("signedMoveId"),
    onSigningStarted,
  });
  // Wrap the startSigning function to also reset the error state before signing
  const startSigningWithErrorReset = useCallback(
    (args: Parameters<typeof startSigning.mutate>[0]) => {
      setShouldShowRequiredError(false);
      startSigning.mutate(args);
    },
    [startSigning]
  );

  const nextMoveId = (scriveStatus && nextMoveToSign(moves, scriveStatus))?.id;

  return (
    <article className="transfer-pension-digital-signing">
      <span className="header">
        <Typography type="h2">
          <TranslatedText
            id={"sweden.transfer-pension.digital-signing.header"}
          />
        </Typography>
        {hasManualSigningMove && <SignStepIndicator step={1} />}
      </span>
      <Typography type="body">
        <TranslatedText
          id={"sweden.transfer-pension.digital-signing.ingress"}
        />
      </Typography>
      <ul className="list">
        {moves.map((move) => {
          const status = scriveStatus?.find(
            (status) => status.accountId === move.id
          );
          const isNextToSign = nextMoveId === move.id;
          return (
            <li className="list-item" key={move.id}>
              <SignMove
                move={move}
                status={status}
                isNextToSign={isNextToSign}
                startSigning={startSigningWithErrorReset}
              />
            </li>
          );
        })}
      </ul>
      {shouldShowRequiredError ? (
        <div className="error">
          <InputErrorList
            errorMessages={[
              intl.formatMessage({
                id: "sweden.transfer-pension.digital-signing.not-complete-error",
              }),
            ]}
          />
        </div>
      ) : (
        <div className="error">
          <div className="invisible">Invisible</div>
        </div>
      )}
      {shouldShowLeftWarning && (
        <Modal
          header={intl.formatMessage({
            id: "sweden.transfer-pension.digital-signing.not-complete-warning.header",
          })}
          showModal={shouldShowLeftWarning}
          closeOnOverlayClick
          onClose={() => {
            setShouldShowLeftWarning(false);
          }}
          width={420}
        >
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.digital-signing.not-complete-warning.body" />
          </Typography>
          <Button
            block
            variant="primary"
            type="button"
            label={
              <FormattedMessage id="sweden.transfer-pension.digital-signing.not-complete-warning.button.stay" />
            }
            onClick={() => {
              setShouldShowLeftWarning(false);
            }}
          />
          <Button
            block
            variant="outlined"
            type="button"
            label={
              <FormattedMessage id="sweden.transfer-pension.digital-signing.not-complete-warning.button.next" />
            }
            onClick={() => {
              next(caseId);
            }}
          />
        </Modal>
      )}
      <Button
        className="next"
        block
        variant="primary"
        type="button"
        label={
          <FormattedMessage id="sweden.transfer-pension.digital-signing.next.button" />
        }
        onClick={() => {
          if (!(scriveStatus && atLeastOneIsSigned(scriveStatus))) {
            setShouldShowRequiredError(true);
            return;
          }
          if (!(scriveStatus && allIsSigned(scriveStatus))) {
            setShouldShowLeftWarning(true);
            return;
          }
          next(caseId);
        }}
      />
    </article>
  );
};
