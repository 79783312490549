import { CardList, CardListItem, NewIcon, Typography } from "@lysaab/ui-2";
import { useContext } from "react";

import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../context/LocalizationContext";
import { ContactPhoneNumbers } from "../../../../../components/Contact";
import { LysaCountry } from "@lysaab/shared";
import { Link } from "react-router-dom";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../../../../../pages/messages/MessagesPage";

import "./Help.scss";

// enum HelpType {
//   FAQ = "FAQ",
//   MESSAGE = "MESSAGE",
//   CALL = "CALL",
// }

export const Help = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const localPhoneNumber = intl.formatMessage(
    ContactPhoneNumbers[localizationContext.state.country || LysaCountry.SWEDEN]
  );

  return (
    <section className="transfer-pension-intro-help">
      <Typography type="h4" className="help-header">
        <TranslatedText id="sweden.transfer-pension.helper-section.header" />
      </Typography>
      <Typography type="body" className="help-header">
        <TranslatedText id="sweden.transfer-pension.intro.helper-section.byline" />
      </Typography>
      <CardList shadowStyle={false}>
        <div className="card-list-item-wrapper">
          <Link
            to={getNavLink(MESSAGES_PAGE_URL)}
            // onClick={() => {
            //   plausibleTrack(HelpType.MESSAGE);
            // }}
          >
            <CardListItem>
              <div className="left-section">
                <NewIcon.Message />
                <div className="text-wrapper">
                  <Typography type="label">
                    <TranslatedText id="sweden.transfer-pension.intro.helper-section.message.header" />
                  </Typography>
                  <Typography type="body" variant="secondary" component="span">
                    <TranslatedText id="sweden.transfer-pension.intro.helper-section.message.description" />
                  </Typography>
                </div>
              </div>
              <NewIcon.ChevronRight />
            </CardListItem>
          </Link>
        </div>
        <div className="card-list-item-wrapper">
          <a
            href={`tel:${localPhoneNumber}`}
            target="_blank"
            rel="noreferrer"
            // onClick={() => {
            //   plausibleTrack(HelpType.CALL);
            // }}
          >
            <CardListItem>
              <div className="left-section">
                <NewIcon.Phone />
                <div className="text-wrapper">
                  <Typography type="label">
                    <TranslatedText
                      id="sweden.transfer-pension.intro.helper-section.call.header"
                      values={{
                        phoneNumber: localPhoneNumber,
                      }}
                    />
                  </Typography>
                  <Typography type="body" variant="secondary" component="span">
                    <TranslatedText id="sweden.transfer-pension.intro.helper-section.call.description" />
                  </Typography>
                </div>
              </div>
              <NewIcon.ChevronRight />
            </CardListItem>
          </a>
        </div>
      </CardList>
    </section>
  );
};

// TODO: Check if we should update this

// const plausibleTrack = (helpType: HelpType) => {
//   if (window.plausible) {
//     window.plausible("WITHDRAWAL_HELP_SECTION", {
//       props: {
//         alternative: helpType,
//       },
//     });
//   }
// };
