import { FunctionComponent, useContext, useState } from "react";

import { useIntl } from "react-intl";
import { MAX_AGE } from "../TransferContext";
import { getUserAge } from "../utils/userAge";
import { Ingress } from "../components/ingress/Ingress";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";
import { UserContext, useUser } from "../../../../../context/UserContext";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { Modal } from "../../../../../components/modal/Modal";
import { LegalEntityType } from "../../../../../data/dataLogin";
import LeafImage from "../../../../../assets/illustration-leaf.png";
import { ReactComponent as RocketIllustration } from "./rocket.svg";
import { ReactComponent as GraphIllustration } from "./graph.svg";
import { ReactComponent as WelcomeIllustration } from "./welcome.svg";

import "./Intro.scss";
import { FAQ } from "./FAQ";
import { Help } from "./Help";

interface Props {
  next: () => void;
  exit: () => void;
}

export const Intro: FunctionComponent<Props> = ({ next, exit }) => {
  const intl = useIntl();
  const user = useUser();
  const userContext = useContext(UserContext);
  const age = getUserAge(user.tin);
  const [readMore, setReadMore] = useState(false);

  if (age && age > MAX_AGE) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.age"}
              values={{
                max: (text) => {
                  return <span>{MAX_AGE}</span>;
                },
              }}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  if (userContext.state.legalEntityType === LegalEntityType.CORPORATION) {
    return (
      <article className="transfer-pension-intro">
        <header>
          <NewIcon.AlertOutline
            size={96}
            primaryColor="var(--lysa-icon-secondary)"
          />
          <Typography type="display-small">
            <TranslatedText id={"sweden.transfer-pension.intro.block.header"} />
          </Typography>
          <Ingress>
            <TranslatedText
              id={"sweden.transfer-pension.intro.block.reason.corporation"}
            />
          </Ingress>
        </header>
        <Button
          style={{ marginTop: "6rem" }}
          block
          variant="primary"
          type="button"
          label={intl.formatMessage({
            id: "sweden.transfer-pension.intro.exit.button",
          })}
          onClick={exit}
        />
      </article>
    );
  }

  return (
    <article className="transfer-pension-intro">
      <header>
        <img src={LeafImage} alt="Leaf" width="200px" height="auto" />
        <Typography type="display-small">
          <TranslatedText
            id={"sweden.transfer-pension.intro.header"}
            values={{
              first: (text) => {
                return (
                  <span>
                    {text}
                    <br />
                  </span>
                );
              },
              second: (text) => {
                return <span>{text}</span>;
              },
            }}
          />
        </Typography>
        <Typography type="h3" className="ingress">
          <TranslatedText id={"sweden.transfer-pension.intro.ingress"} />
        </Typography>
        <section className="time-estimate">
          <NewIcon.Clock size={16} primaryColor="var(--lysa-text-secondary)" />
          <TranslatedText id={"sweden.transfer-pension.intro.time-estimate"} />
        </section>
      </header>
      <hr className="divider" />
      <ul className="usp-list">
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Check primaryColor="#1840e3" />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-1.header"}
              />
            </Typography>
          </span>
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Check primaryColor="#1840e3" />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-2.header"}
              />
            </Typography>
          </span>
        </li>
        <li className="list-item">
          <span className="icon-circle">
            <NewIcon.Check primaryColor="#1840e3" />
          </span>
          <span className="list-item-text">
            <Typography type="label">
              <TranslatedText
                id={"sweden.transfer-pension.intro.list-item-3.header"}
              />
            </Typography>
          </span>
        </li>
      </ul>
      <hr className="divider" />
      <section className="why">
        <Typography type="h2" className="why-header">
          <TranslatedText id="sweden.transfer-pension.intro.why.header" />
        </Typography>
        <div className="why-sub-section">
          <RocketIllustration />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-one.header" />
          </Typography>
          <Typography>
            <TranslatedText
              id="sweden.transfer-pension.intro.why.sub-section-one.body"
              values={{
                link: (text) => {
                  return (
                    <button
                      className="read-more-button"
                      onClick={() => setReadMore(true)}
                    >
                      {text}
                    </button>
                  );
                },
              }}
            />
          </Typography>
        </div>
        <div className="why-sub-section grey-bg">
          <GraphIllustration />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-two.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-two.body" />
          </Typography>
        </div>
        <div className="why-sub-section">
          <WelcomeIllustration />
          <Typography type="h3" className="why-sub-section-header">
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-three.header" />
          </Typography>
          <Typography>
            <TranslatedText id="sweden.transfer-pension.intro.why.sub-section-three.body" />
          </Typography>
        </div>
        <section className="contact grey-bg">
          <FAQ />
          <Help />
        </section>
        {readMore && (
          <Modal
            header={intl.formatMessage({
              id: "sweden.transfer-pension.intro.read-more.fees.heading",
            })}
            showModal={readMore}
            onClose={() => {
              setReadMore(false);
            }}
            closeOnOverlayClick
          >
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.one" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.two" />
            </Typography>
            <Typography type="body">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.body.three" />
            </Typography>
            <Typography type="body-small">
              <TranslatedText id="sweden.transfer-pension.intro.read-more.fees.footnote" />
            </Typography>
          </Modal>
        )}
      </section>
      <Button
        className="next"
        block
        variant="primary"
        onClick={next}
        label={intl.formatMessage({
          id: "sweden.transfer-pension.intro.button",
        })}
      />
    </article>
  );
};
